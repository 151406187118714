/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { Container, Enterprise, Left, StartingPoint } from './styles';

import BancoCentral from './assets/BancoCentral.svg';
import InfoMoney from './assets/InfoMoney.svg';
import Prolider from './assets/Prolider.svg';
import Startupi from './assets/Startupi.svg';
import Pipeline from './assets/Pipeline.svg';
import DigitalMoney from './assets/DigitalMoney.svg';
import ManDrive from './assets/ManDrive.jpg';

const Section2: React.FC = () => {
  const [width, setWidth] = useState(window.innerWidth)

  window.addEventListener('resize', () => {
    setWidth(window.innerWidth)
  })

  return (
    <Container>
      <Enterprise>
        <a href='https://www.bcb.gov.br/detalhenoticia/565/noticia' target="_blank" rel="noreferrer">
          <img src={BancoCentral} alt="..." />
        </a>
        <a href='https://www.infomoney.com.br/do-zero-ao-topo/de-open-banking-ao-real-digital-veja-11-startups-selecionadas-para-o-laboratorio-de-experimentos-do-banco-central/' target="_blank" rel="noreferrer">
          <img src={InfoMoney} alt="..." />
        </a>
        <a href='https://www.programaprolider.org/legado-do-prolider/plific-legado-do-prolider' target="_blank" rel="noreferrer">
          <img src={Prolider} alt="..." />
        </a>
        <a href='https://startupi.com.br/2021/07/startup-de-open-finance-destinada-a-entregadores-e-motoristas-recebe-aporte-da-investtools/' target="_blank" rel="noreferrer">
          <img src={Startupi} alt="..." />
        </a>
        <a href='https://pipelinevalor.globo.com/startups/noticia/plific-quer-tirar-as-contas-dos-motoristas-de-uber-do-caderninho.ghtml' target="_blank" rel="noreferrer">
          <img src={Pipeline} alt="..." />
        </a>
        <a href='https://www.digitalmoneyinforme.com.br/o-credito-chega-para-entregadores-e-motoristas-de-app/' target="_blank" rel="noreferrer">
          <img src={DigitalMoney} alt="..." />
        </a>
      </Enterprise>
      <StartingPoint>
        <Left>
          <p>Ponto de partida</p>
          <h1>
            Os gig workers – ou trabalhadores de aplicativos – são uma nova
            vertente no mercado de trabalho que ainda não possuem vez no mercado
            de crédito, pelo seu estilo de trabalho. Desta forma, quem atua
            neste meio, tem acesso a crédito próximo a zero devido a ausência de
            um histórico de crédito. Isso por que os modelos de avaliação de
            crédito existentes não levam em consideração esse estilo de
            trabalho.
          </h1>
          <h2>
            💵<br />
            Isso fica evidente quando vemos que mais de 50% daqueles que
            possuem um cartão de crédito tem um <span>limite de até R$ 1.000,00</span>. Um valor totalmente distante do
            ganho médio de um trabalhador desta classe que gira, em torno, de <span>R$
              2.327,00</span>. Praticamente o dobro do salário mínimo nacional no ano de
            2022.
          </h2>
          {width < 1199 && (
            <img src={ManDrive} alt="..." />
          )}
          <h3>
            Atuamos para fazer com que o crédito seja uma ferramenta de
            transformação e não de dívidas. Analisamos as informações de
            trabalho dos nossos usuários para fazer uma análise e gerar um
            histórico de crédito com dados alternativos que realmente importam.
            Então o histórico de trabalho nas plataformas como iFood, Rappi e
            Uber são uma fonte de dados fundamentais para ir de encontro ao
            nosso objetivo de democratizar o acesso a um crédito mais justo.
          </h3>
        </Left>
        {width > 1199 && (
          <img src={ManDrive} alt="..." />
        )}
      </StartingPoint>
    </Container>
  );
};

export default Section2;
