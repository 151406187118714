import styled, { createGlobalStyle } from 'styled-components';

export const toastStyles = createGlobalStyle`
  [data-sonner-toaster] {
    font-family: 'Poppins', sans-serif;
  }

  [data-sonner-toast] {
    border-radius: 8px;
    padding: 16px;
    border: none;
  }

  [data-sonner-toast][data-type="success"] {
    background: #28a745;
    color: white;
  }

  [data-sonner-toast][data-type="error"] {
    background: #dc3545;
    color: white;
  }

  [data-sonner-toast][data-type="warning"] {
    background: #ffc107;
    color: #2a2a2a;
  }
`;

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  label {
    color: #666;
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 500;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 8rem 5rem 5rem;
  box-sizing: border-box;
  position: relative;

  form {
    width: 100%;
    max-width: 800px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  #form {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-self: center;

    h2 {
      color: #2a2a2a;
      font-size: 18px;
      text-align: center;
      max-width: 100%;
      margin: 20px 0;
      padding-top: 20px;
    }

    h3 {
      color: #2a2a2a;
      font-size: 15px;
      text-align: center;
      max-width: 100%;
      margin-bottom: 10px;
    }

    h4,
    a {
      display: flex;
      justify-content: center;
      flex-direction: column;
      button {
        border: none;
        background-color: transparent;
        color: #2a2a2a;
        font-size: 13px;
        text-align: center;
        max-width: 100%;
        text-decoration: underline;
        font-weight: 500;
      }
    }

    & > div {
      margin-bottom: 20px;
    }

    & > div:nth-last-child(1) {
      margin-bottom: 0;
    }

    & > button {
      margin: 30px 0;
      align-self: center;
    }
  }

  @media (max-width: 1024px) {
    padding: 7rem 2rem 3rem;

    form {
      max-width: 100%;
    }

    #form h2 {
      font-size: 16px;
      padding-top: 15px;
    }
  }

  @media (max-width: 428px) {
    padding: 6rem 1rem 2rem;

    #form {
      h2 {
        font-size: 15px;
        padding-top: 10px;
        margin: 15px 0;
      }

      & > button {
        margin: 20px 0;
      }
    }
  }

  @media (max-width: 320px) {
    padding: 5.5rem 1rem 2rem;

    #form h2 {
      font-size: 14px;
      padding-top: 8px;
    }
  }
`;
