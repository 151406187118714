import styled from 'styled-components';

export const Box = styled.div`
  width: 100%;
  background-color: #111111;
`;

export const Container = styled.footer`
  width: 1199px;
  height: 113px;

  background-color: #111111;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;

  box-sizing: border-box;

  padding: 0 0rem;

  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 22.5px;
    color: #ffffff;

    margin: 2px 0;
  }

  @media (max-width: 1199px) {
    width: 100vw;
    margin: auto;

    padding: 0 1rem;

    p {
      margin: auto;

      font-size: 13px;
    }
  }
`;
