import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  background-color: #f5f5f5;

  width: 100%;
  box-sizing: border-box;
  padding: 5rem;

  p {
    font-size: 2vw;
    font-weight: bold;
    color: #171717;
  }

  h1 {
    font-size: 1.5vw;
    color: #171717;
  }

  a {
    font-size: 1.5vw;
    color: #eec03f;
    cursor: pointer;
  }

  @media (max-width: 1199px) {
    padding: 1rem;
    height: auto;

    padding: 3rem 1rem;

    p {
      font-size: 5vw;
      text-align: center;
      margin-bottom: 1rem;
    }

    h1 {
      font-size: 4vw;
      margin-bottom: 1rem;
      text-align: center;
    }

    a {
      font-size: 4vw;
    }
  }
`;
