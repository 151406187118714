import styled from 'styled-components';

export const Container = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  display: inline-flex;
  z-index: 999; /* z-index para jogar para frente e sobrepor tudo */

  .text {
    text-align: center;
    margin-top: 15px;
    font-size: 30px;
    margin-top: -100px;
  }

  .text p {
    font-size: 30px;
    color: #eec03f;
    font-weight: 400;
  }
  /*
  .balls {
    display: flex;
    justify-content: center;
  }
  .balls > div {
    display: inline-block;
    background-color: #ffc72a;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    margin: 3px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: animateBalls;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  .balls > div:nth-child(1) {
    animation-duration: 0.75s;
    animation-delay: 0;
  }
  .balls > div:nth-child(2) {
    animation-duration: 0.75s;
    animation-delay: 0.12s;
  }
  .balls > div:nth-child(3) {
    animation-duration: 0.75s;
    animation-delay: 0.24s;
  }

  @keyframes animateBalls {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
    16% {
      -webkit-transform: scale(0.1);
      transform: scale(0.1);
      opacity: 0.7;
    }
    33% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  } */
  /* end: container */
`;
