import styled from 'styled-components';

import BackgroundImage from './assets/background.jpg';
import BackgroundMobile from './assets/backgroundMobile.jpg';

export const Background = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(${BackgroundImage});
  background-repeat: no-repeat;
  background-size: cover;

  height: 60vw;
  box-sizing: border-box;

  p {
    width: 75%;
    font-size: 3vw;
    font-weight: bold;
    text-align: center;

    margin: 1em;

    &::before {
      content: 'Revolucionando ';
      color: #eec03f;
    }
    &::after {
      content: ' crédito';
      color: #eec03f;
    }
  }

  span {
    width: 50%;
    font-size: 1.5vw;
    text-align: center;
  }

  @media (max-width: 1199px) {
    background: url(${BackgroundMobile});
    height: 90vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: cover;

    p {
      width: 90%;
      font-size: 6vw;
    }

    span {
      width: 80%;
      font-size: 4vw;
    }
  }
`;
