import React, { ButtonHTMLAttributes } from 'react';
import {
  Container,
  // Image
} from './styles';

// import IconGoogle from './assets/google_white.svg';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const DownloadButtonAndroid: React.FC<ButtonProps> = ({ ...rest }) => {
  return <Container {...rest}>Fazer parte</Container>;
};

export default DownloadButtonAndroid;
