import React from 'react';
// import { CustomDialog } from 'react-st-modal';
import {
  Container,
  Left,
  Middleware,
  // Right,
  ContainerContact,
  SocialMidia,
  // TitleList,
  TopicList,
  Box,
} from './styles';

// import instagram_logo from './assets/instagram.svg';
import linkedin_logo from './assets/linkedin.svg';

// import ModalAppleStore from '../ModalAppleStore';
// import DownloadButtonAndroid from '../DownloadButtonAndroid';
// import DownloadButtonApple from '../DownloadButtonApple';

const Footer: React.FC = () => {
  return (
    <Box>
      <Container>
        <Left>
          <h2>Plific</h2>
          <p>
            A solução financeira construída exclusivamente para a GIG Economy.
          </p>
          <ul>
            {/* <li>
              <SocialMidia
                img={instagram_logo}
                href="https://www.instagram.com/plific.br/"
                target="_blanck"
              />
            </li> */}
            <li>
              <SocialMidia
                img={linkedin_logo}
                href="https://br.linkedin.com/company/plific"
                target="_blanck"
              />
            </li>
          </ul>
        </Left>
        <ContainerContact>
          <Middleware>
            <ul>
              <li>
                <TopicList>Contato</TopicList>
              </li>
              <li>
                <TopicList
                  onClick={() =>
                    window.open(
                      'mailto:contato@plific.com',
                      '_blank',
                      'noopener,noreferrer',
                    )
                  }
                >
                  contato@plific.com
                </TopicList>
              </li>
              <li>
                <TopicList>Suporte</TopicList>
              </li>
              <li>
                <TopicList
                  onClick={() =>
                    window.open(
                      'mailto:meajuda@plific.com',
                      '_blank',
                      'noopener,noreferrer',
                    )
                  }
                >
                  meajuda@plific.com
                </TopicList>
              </li>
            </ul>
          </Middleware>

          {/* <Right>
            <TitleList>Baixe nosso app</TitleList>
            <div>
              <DownloadButtonAndroid
                onClick={() =>
                  window.open(
                    'https://play.google.com/store/apps/details?id=com.plificmobile',
                    '_blank',
                    'noopener,noreferrer',
                  )
                }
              />
              <DownloadButtonApple
                onClick={() => CustomDialog(<ModalAppleStore />)}
              />
            </div>
          </Right> */}
        </ContainerContact>
      </Container>
    </Box>
  );
};

export default Footer;
