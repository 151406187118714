import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  // Reset style
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  // Global Style
  * {
    margin: 0;
    padding: 0;
    box-sizing: 0;
    outline: 0;
  }

  // Estilização da barra de rolagem
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: #adadad;
    border-radius: 4px;
    transition: background 0.2s ease-in-out;

    &:hover {
      background: #676767;
    }
  }

  // Firefox scrollbar
  * {
    scrollbar-width: thin;
    scrollbar-color: #adadad #f5f5f5;
  }

  button, input[type=button] {
    cursor: pointer;
  }

  body {
    background-color: #f5f5f5;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    color: var(--white);

    width: 100%;

    --white: #f5f5f5;
    --gray: #676767;
    --black: #272727;
    --yellow: #EEC03F;
    --bg-color: #000000;

    border-right: none !important;

    @media (max-width: 1199px) {
      width: 100vw;
    }
  }

  a {
    color: var(--white);
    text-decoration: none;
    cursor: pointer;
  }

  body, input, button {
    font-family: 'Poppins', sans-serif;
  }

  img, section {
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
    -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
    animation: fadein 2s;

    @keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
    }

    /* Firefox < 16 */
    @-moz-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
    }

    /* Safari, Chrome and Opera > 12.1 */
    @-webkit-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
    }

    /* Internet Explorer */
    @-ms-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
    }
  }

  .container {
    max-width: 1100px;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;

    @media (max-width: 1199px) {
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
      /* margin: 0; */
    }
  }

  :root {
    --st-dialog-baseWidth: auto;
    --st-dialog-baseHeight: auto;
    --st-dialog-maxWidth: 100%;
    --st-dialog-maxHeight: 100%;
    --st-modal-boxShadow: 0 10px 50px rgba(0, 0, 0, 0.8);
    --st-modal-borderRadius: 15px;

    --st-modal-transformVisible: translate3d(-50%, -50%, 0) scale(1);
    --st-modal-transformHidden: translate3d(-50%, -50%, 0) scale(0.1);

    @media (max-width: 1199px) {
      --st-dialog-maxWidth: 95%;
      --st-dialog-maxHeight: 95%;
    }
  }
`;
