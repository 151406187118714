import React, {
  useState,
  useEffect,
  useCallback,
  InputHTMLAttributes,
  useRef,
} from 'react';
import { useField } from '@unform/core';

import { FiAlertCircle } from 'react-icons/fi';

import {
  maskBirthday,
  maskCpf,
  maskPhone,
  maskCep,
  maskCurrency,
} from '../../utils/masks';

import { Container, Error, Label } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  inputMaskChange?: any;
  label?: string;
  mask?: 'cpf' | 'birthday' | 'phone' | 'cep' | 'currency' | 'none';
}

const Input: React.FC<InputProps> = ({
  name,
  inputMaskChange,
  label,
  mask,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
    if (inputRef.current && name === 'date') {
      inputRef.current.type = 'date';
    }
  }, [name]);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
    if (inputRef.current && name === 'date' && !isFilled) {
      inputRef.current.type = 'text';
    }
  }, [name, isFilled]);

  function handleChangeMask(text: string): string | undefined {
    let value;
    if (mask === 'birthday') {
      value = maskBirthday(text);
      inputMaskChange(value);
    }

    if (mask === 'cpf') {
      value = maskCpf(text);
      inputMaskChange(value);
    }

    if (mask === 'phone') {
      value = maskPhone(text);

      inputMaskChange(value);
    }

    if (mask === 'cep') {
      value = maskCep(text);
      inputMaskChange(value);
    }

    if (mask === 'currency') {
      value = maskCurrency(text);
      inputMaskChange(value);
    }

    if (mask === 'none') {
      value = text;
      inputMaskChange(text);
    }
    return value;
  }

  const handleChange = useCallback(
    value => {
      if (!mask) {
        if (inputRef.current?.value) {
          inputRef.current.value = value;
        }
        return;
      }
      const formatedValue = handleChangeMask(value) || '';
      if (inputRef.current?.value) {
        inputRef.current.value = formatedValue;
      }
    },
    [handleChangeMask],
  );

  return (
    <>
      {label && <Label>{label}</Label>}
      <Container isFocused={isFocused} isFilled={isFilled} isErrored={!!error}>
        <input
          ref={inputRef}
          type="text"
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          autoComplete="none"
          onChange={event => handleChange(event.target.value)}
          {...rest}
        />
        {error && (
          <Error title={error}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </Container>
    </>
  );
};
export default Input;
