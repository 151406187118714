import React from 'react';
import { Background } from './styles';

const Section1: React.FC = () => {
  return (
    <Background>
      <p>a vida financeira dos gig workers através do </p>
      <span>
        Usamos dados alternativos para construir um histórico de crédito para
        aqueles que não são vistos pelas financeiras tradicionais.
      </span>
    </Background>
  );
};

export default Section1;
