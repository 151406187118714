/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useCallback, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Toaster, toast } from 'sonner';

import Input from '../../../Input';
import Select from '../../../Select';
import Button from '../../../ButtonSubmit';
import { Container, toastStyles as ToastStyles, FormField } from './styles';
import getValidationErrors from '../../../../utils/getValidationErrors';

interface ModalRegisterData {
  fullname: string;
  empresa: string;
  estado: string;
  phone: string;
  email: string;
  cicloPagamento: string;
  volumeRepasse: string;
  prestadoresAtivos: string;
  estabelecimentosAtivos: string;
}

const estadosOptions = [
  { value: '', label: 'Selecione seu estado' },
  ...[
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ].map(estado => ({ value: estado, label: estado })),
];

const cicloPagamentoOptions = [
  { value: '', label: 'Selecione o ciclo de pagamento' },
  { value: 'diario', label: 'Diário' },
  { value: 'semanal', label: 'Semanal' },
  { value: 'quinzenal', label: 'Quinzenal' },
  { value: 'mensal', label: 'Mensal' },
  { value: 'outro', label: 'Outro' },
];

const volumeRepasseOptions = [
  { value: '', label: 'Selecione o volume de repasse' },
  { value: 'ate50', label: 'Até 50 mil' },
  { value: '50a100', label: 'De 50 mil - 100 mil' },
  { value: '100a250', label: 'De 100 mil - 250 mil' },
  { value: '250a500', label: 'De 250 mil - 500 mil' },
  { value: 'mais500', label: 'Mais de 500 mil' },
];

const prestadoresOptions = [
  { value: '', label: 'Selecione a quantidade de prestadores' },
  { value: 'nenhum', label: 'Não trabalho com prestadores' },
  { value: 'ate50', label: 'Até 50' },
  { value: '51a100', label: 'Entre 51 e 100' },
  { value: '101a500', label: 'Entre 101 e 500' },
  { value: '501a1000', label: 'Entre 501 e 1000' },
  { value: 'mais1000', label: 'Mais de 1000' },
];

const estabelecimentosOptions = [
  { value: '', label: 'Selecione a quantidade de estabelecimentos' },
  { value: 'nenhum', label: 'Não trabalho com estabelecimentos' },
  { value: 'ate50', label: 'Até 50' },
  { value: '51a100', label: 'Entre 51 e 100' },
  { value: '101a500', label: 'Entre 101 e 500' },
  { value: '501a1000', label: 'Entre 501 e 1000' },
  { value: 'mais1000', label: 'Mais de 1000' },
];

const GOOGLE_SHEETS_URL = process.env.REACT_APP_GOOGLE_SHEETS_URL;

const Modal1: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [, setPhone] = useState('');

  const sendToGoogleSheets = async (
    data: ModalRegisterData,
  ): Promise<boolean> => {
    if (!GOOGLE_SHEETS_URL) {
      return false;
    }

    try {
      const formattedData = {
        ...data,
        cicloPagamento:
          cicloPagamentoOptions.find(
            option => option.value === data.cicloPagamento,
          )?.label || data.cicloPagamento,
        volumeRepasse:
          volumeRepasseOptions.find(
            option => option.value === data.volumeRepasse,
          )?.label || data.volumeRepasse,
        prestadoresAtivos:
          prestadoresOptions.find(
            option => option.value === data.prestadoresAtivos,
          )?.label || data.prestadoresAtivos,
        estabelecimentosAtivos:
          estabelecimentosOptions.find(
            option => option.value === data.estabelecimentosAtivos,
          )?.label || data.estabelecimentosAtivos,
      };

      await fetch(GOOGLE_SHEETS_URL, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formattedData),
      });

      return true;
    } catch (error) {
      return false;
    }
  };

  const handleSubmit = useCallback(
    async (data: ModalRegisterData): Promise<void> => {
      try {
        setIsSubmitting(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          fullname: Yup.string().required('Nome completo é obrigatório'),
          empresa: Yup.string().required('Nome da empresa é obrigatório'),
          estado: Yup.string().required('Estado é obrigatório'),
          email: Yup.string()
            .matches(
              /^([\w.-]+)@([\w-]+)((\.(\w){2,3})+)$/gm,
              'E-mail inválido',
            )
            .required('E-mail obrigatório'),
          phone: Yup.string().length(15, 'Telefone incompleto'),
          cicloPagamento: Yup.string().required(
            'Ciclo de pagamento é obrigatório',
          ),
          volumeRepasse: Yup.string().required(
            'Volume de repasse é obrigatório',
          ),
          prestadoresAtivos: Yup.string().required(
            'Número de prestadores ativos é obrigatório',
          ),
          estabelecimentosAtivos: Yup.string().required(
            'Número de estabelecimentos ativos é obrigatório',
          ),
        });

        await schema.validate(data, { abortEarly: false });

        try {
          const sheetsResult = await sendToGoogleSheets(data);

          if (sheetsResult) {
            toast.success(
              'Cadastro realizado com sucesso! Entraremos em contato.',
            );
            formRef.current?.reset();
          } else {
            toast.error(
              'Houve um problema ao salvar seus dados. Por favor, tente novamente.',
            );
          }
        } catch (apiError: any) {
          let errorMessage = 'Erro ao realizar cadastro. Tente novamente.';

          if (apiError.response?.data?.message) {
            errorMessage = apiError.response.data.message;
          } else if (apiError.message) {
            errorMessage = apiError.message;
          }

          toast.error(errorMessage);

          throw apiError;
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          toast.warning('Por favor, preencha todos os campos corretamente.');

          return;
        }

        toast.error('Ocorreu um erro inesperado. Tente novamente.');
      } finally {
        setIsSubmitting(false);
      }
    },
    [],
  );

  return (
    <Container>
      <ToastStyles />
      <Toaster theme="system" position="top-right" expand={false} richColors />

      <Form ref={formRef} onSubmit={handleSubmit}>
        <div id="form">
          <h2>
            A Plific traz uma nova linha de receita para sua plataforma através
            de pagamento instantâneo dos autônomos cadastrados.
            <br />
            Registre-se e fale conosco!
          </h2>

          <FormField>
            <label htmlFor="fullname">Nome completo</label>
            <Input
              id="fullname"
              name="fullname"
              placeholder="Digite seu nome completo"
            />
          </FormField>

          <FormField>
            <label htmlFor="empresa">Nome da Empresa</label>
            <Input
              id="empresa"
              name="empresa"
              placeholder="Digite o nome da empresa"
            />
          </FormField>

          <FormField>
            <label htmlFor="email">E-mail</label>
            <Input id="email" name="email" placeholder="Digite seu e-mail" />
          </FormField>

          <FormField>
            <label htmlFor="phone">Telefone</label>
            <Input
              id="phone"
              name="phone"
              mask="phone"
              type="tel"
              maxLength={15}
              inputMaskChange={(text: string) => setPhone(text)}
              placeholder="Digite seu telefone"
              inputMode="numeric"
            />
          </FormField>

          <FormField>
            <label htmlFor="estado">Estado</label>
            <Select
              id="estado"
              name="estado"
              options={estadosOptions}
              className="form-select"
            />
          </FormField>

          <FormField>
            <label htmlFor="cicloPagamento">Ciclo de pagamento</label>
            <Select
              id="cicloPagamento"
              name="cicloPagamento"
              options={cicloPagamentoOptions}
              className="form-select"
            />
          </FormField>

          <FormField>
            <label htmlFor="volumeRepasse">Volume de repasse no mês</label>
            <Select
              id="volumeRepasse"
              name="volumeRepasse"
              options={volumeRepasseOptions}
              className="form-select"
            />
          </FormField>

          <FormField>
            <label htmlFor="prestadoresAtivos">
              Prestadores ativos na sua plataforma
            </label>
            <Select
              id="prestadoresAtivos"
              name="prestadoresAtivos"
              options={prestadoresOptions}
              className="form-select"
            />
          </FormField>

          <FormField>
            <label htmlFor="estabelecimentosAtivos">
              Estabelecimentos ativos na sua plataforma
            </label>
            <Select
              id="estabelecimentosAtivos"
              name="estabelecimentosAtivos"
              options={estabelecimentosOptions}
              className="form-select"
            />
          </FormField>

          <Button
            style={{
              backgroundColor: '#EEC03F',
              color: '#2a2a2a',
            }}
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Enviando...' : 'Enviar'}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default Modal1;
