import React from 'react';
import { Box, Container } from './styles';

const SubFooter: React.FC = () => {
  return (
    <Box>
      <Container>
        <p>© 2024 Plific Finanças e Serviços LTDA - CNPJ: 42.239.933/0001-86</p>
        <p>
          Estrada M`boi Mirim, 2298, Bloco 3, Apt 72 - Jardim Regina - São
          Paulo, SP - 04906-900
        </p>
      </Container>
    </Box>
  );
};

export default SubFooter;
