import React from 'react';
import { Container, ContainerTitle, Enterprise } from './styles';

import LiftLab from './assets/LiftLab.svg';
import InvestTools from './assets/Investtools.svg';
import Inovativa from './assets/Inovativa.svg';
import StartupChile from './assets/StartupChile.svg';

const Section4: React.FC = () => {
  return (
    <Container>
      <ContainerTitle>
        <p>Quem confia</p>
      </ContainerTitle>
      <Enterprise>
        <img src={LiftLab} alt="..." />
        <img src={InvestTools} alt="..." />
        <img src={Inovativa} alt="..." />
        <img src={StartupChile} alt="..." />
      </Enterprise>
    </Container>
  );
};

export default Section4;
