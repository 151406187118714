import React from 'react';
import Lottie from 'react-lottie';
import { Link } from 'react-router-dom';
import { Container, Row } from './styles';
import Person404 from './assets/person404.json';
import Logo from './assets/Plific..png';

const PageNotFound: React.FC = () => {
  document.title = 'Plific - Página não encontrada';

  const width = window.innerWidth;

  return (
    <Container>
      <Link to="/">
        <img src={Logo} alt="..." />
      </Link>

      <Row>
        <div>
          <span>Fim da linha meu rapaz.</span>
          <span>
            <strong>Erro 404</strong> - Página não encontrada
          </span>
        </div>
        <div>
          <Lottie
            options={{ animationData: Person404 }}
            width={width < 1199 ? width * 0.9 : 400}
            height={400}
          />
        </div>
      </Row>
    </Container>
  );
};

export default PageNotFound;
