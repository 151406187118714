import styled from 'styled-components';

interface SocialMidiaProps {
  img: string;
}

export const Box = styled.div`
  width: 100%;
  background-color: #2a2a2a;
`;

export const Container = styled.footer`
  width: 1199px;

  display: flex;
  flex-direction: row;
  box-sizing: border-box;

  background-color: #2a2a2a;
  padding: 2rem 0;

  margin: auto;

  color: #ffffff;

  @media (max-width: 1199px) {
    flex-direction: column;
    align-items: center;
    max-width: 100vw;
    margin: 0;

    padding: 0;
    padding-bottom: 30px;
    padding-top: 30px;
  }
`;

export const Left = styled.div`
  width: 50%;

  margin: 0;

  h2 {
    font-size: 2.5rem;
    font-weight: bolder;
    font-family: 'Montserrat Alternates';
    margin-bottom: 15px;

    &:after {
      font-family: 'Montserrat Alternates';
      content: '.';
      color: #eec03f;
    }
  }

  p {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 25px;
    width: 60%;
    margin-bottom: 25px;
  }

  ul {
    display: flex;
    justify-content: initial;
  }

  a {
    margin-right: 15px;
  }

  @media (max-width: 1199px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    h2 {
      font-size: 40px;
    }

    p {
      width: 90%;
      font-size: 15px;
      font-weight: 500;
      text-align: center;
    }

    ul {
      justify-content: center;
      margin-bottom: 20px;
    }

    a {
      margin: 0 10px;
    }
  }
`;

export const ContainerContact = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-between;

  margin: 0;

  @media (max-width: 1199px) {
    width: 100%;

    box-sizing: border-box;
    padding: 0 1rem;
  }
`;

export const Middleware = styled.div`
  margin: 0;
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  ul > li {
    &:nth-child(3) {
      margin-top: 1rem;
    }
  }
`;

export const Right = styled.div`
  margin: 0;
  div {
    display: flex;
    width: 12rem;
    height: 6rem;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  button {
    width: 12rem;
    height: 45px;
    font-size: 1rem;
    padding: 10px 1.6rem;

    img {
      width: 1.5rem;
    }
  }

  @media (max-width: 1199px) {
    display: flex;
    flex-direction: column;
    width: auto;

    justify-content: center;
    align-items: center;

    div {
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }

    button {
      width: 80%;
      height: 40px;

      font-size: 0.9rem;
      padding: 10px 1rem;

      img {
        width: 1rem;
      }
    }
  }
`;

export const SocialMidia = styled.a<SocialMidiaProps>`
  content: url(${props => props.img});
`;

export const TitleList = styled.h3`
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 16px;

  text-align: center;
  @media (max-width: 1199px) {
    font-size: 14px;
  }
`;

export const TopicList = styled.a`
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 30px;

  @media (max-width: 1199px) {
    font-size: 13px;
  }
`;
