import React from 'react';

// import { CustomDialog } from 'react-st-modal';
import { Background, ContainerButtons, ContainerText } from './style';

import DownloadButtonAndroid from '../../../../components/DownloadButtonAndroid';
// import ModalAppleStore from '../../../../components/ModalAppleStore';
// import DownloadButtonApple from '../../../../components/DownloadButtonApple';

const Home: React.FC = () => {
  return (
    <Background>
      <ContainerText>
        <span>
          A solução financeira construída{' '}
          <span style={{ color: '#eec03f' }}>exclusivamente</span> para a GIG
          Economy
        </span>
        <h1>
          Tenha acesso a um crédito adaptado a sua realidade, ao seu dia-a-dia.
        </h1>
        <ContainerButtons>
          <DownloadButtonAndroid
            onClick={() =>
              window.open(
                'https://api.whatsapp.com/send?phone=5521982015754&text=Ol%C3%A1,%20quero%20fazer%20parte!',
                '_blank',
                'noopener,noreferrer',
              )
            }
          />
          {/* <DownloadButtonApple
            onClick={() => CustomDialog(<ModalAppleStore />)}
          /> */}
        </ContainerButtons>
      </ContainerText>
    </Background>
  );
};

export default Home;
