import styled from 'styled-components';

import BackgroundImage from './assets/BackgroundImage2.jpg';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: conic-gradient(
    #fff 0deg,
    #fff 90deg,
    #f5f5f5 90deg,
    #f5f5f5 180deg,
    #f5f5f5 180deg,
    #f5f5f5 270deg,
    #fff 270deg
  );

  box-sizing: border-box;
  padding: 5rem;

  @media (max-width: 1199px) {
    padding: 4rem 1rem;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 1199px;
  height: 25rem;
  background-color: #ffffff;

  box-sizing: border-box;
  padding: 0;
  border-radius: 15px;

  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);

  @media (max-width: 1199px) {
    width: 100%;
    height: 35rem;
    flex-direction: column;
    padding: 0;
  }
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  padding: 3rem 2rem;

  width: 45%;
  height: 70%;

  box-sizing: border-box;

  p {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #171717;
  }

  span {
    font-size: 1rem;
    text-align: start;
    color: #828282;
  }

  button {
    width: 230px;
    height: 50px;

    border-radius: 35px;
    background-color: #eec03f;
    border: none;
    transition: background-color 0.5s ease-in-out;

    font-size: 1.4rem;
    font-weight: 600;
    color: #f5f5f5;

    &:hover {
      background-color: #eaad00;
    }
  }

  @media (max-width: 1199px) {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-around;
    padding: 3rem 2rem;

    p {
      font-size: 5vw;
      margin-bottom: 1rem;
    }

    span {
      width: auto;
      font-size: 4vw;
      margin-bottom: 3rem;
    }

    button {
      width: 150px;
      height: 40px;

      font-size: 4vw;

      align-self: flex-start;

      &:hover {
      }
    }
  }
`;

export const ContainerImages = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  background: url(${BackgroundImage});
  background-repeat: no-repeat;
  background-size: cover;

  height: 100%;
  width: 50%;

  border-radius: 0 15px 15px 0;

  img {
    display: none;
  }

  @media (max-width: 1199px) {
    width: 100%;
    background: url(${BackgroundImage});
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0 0 15px 15px;
    img {
      display: none;
    }
  }
`;
