import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  align-self: center;

  width: 100%;
  margin: 0;

  background-color: #ffffff;

  box-sizing: border-box;

  @media (max-width: 1199px) {
    width: 100%;
    height: auto;
    padding: 2rem 1rem;
  }
`;

export const ContainerTitle = styled.div`
  width: 1199px;
  margin-top: 3rem;

  p {
    font-size: 2rem;
    font-weight: bold;
    align-self: flex-start;
    color: #171717;

    margin-bottom: 0rem;
  }

  @media (max-width: 1199px) {
    width: 100%;

    p {
      font-size: 1rem;
    }
  }
`;

export const ContainerChart = styled.div`
  display: flex;
  width: 1199px;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 5rem;

  p {
    color: #171717;
  }

  @media (max-width: 1199px) {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  box-sizing: border-box;

  overflow: hidden;

  img {
    width: 30rem;
    min-width: 210px;
  }

  &:nth-child(1) {
    width: 30vw;
    p {
      font-size: 1.5rem;
      margin-bottom: 3rem;
    }

    span {
      color: #727272;
    }
  }

  &:nth-child(2) {
    display: grid;
    align-items: center;
    justify-items: center;
    width: 31rem;

    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 1199px) {
    width: 100%;
    align-items: center;
    justify-content: center;

    &:nth-child(1) {
      width: 100%;

      p {
        align-self: flex-start;
        font-size: 1rem;
        margin-bottom: 0.5rem;
      }

      span {
        color: #727272;
        text-align: start;
      }
    }

    &:nth-child(2) {
      display: grid;
      align-items: center;
      justify-items: center;
      width: 100%;
      margin-top: 2rem;

      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

export const ContainerNumber = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: center !important;

  width: 220px;
  height: 130px;

  border: 2px solid #cdcdcd;
  margin-bottom: 1rem;

  box-sizing: border-box;

  transition: box-shadow 0.1s linear;

  cursor: pointer;

  &:hover {
    box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.3);
    border: 2px solid #eec03f;
  }

  p {
    align-self: center;
    font-size: 2rem;
    margin: 0;
  }

  span {
    font-size: 1rem;
    align-self: center;
    text-align: center;
    color: #828282;
  }

  &:nth-child(1) {
    margin-top: 1rem;
  }

  &:nth-child(2) {
    margin-top: 1rem;
  }

  @media (max-width: 1199px) {
    width: 130px;
    height: 130px;
    margin-bottom: 2rem;

    p {
      font-size: 1rem;
    }

    span {
      font-size: 1rem;
    }
  }
`;
