import styled from 'styled-components';
import BackgroundImage from './assets/BackgroundImage.jpg';
import BackgroundMobile from './assets/Backgroundmobile.png';

export const Background = styled.div`
  flex-direction: column;

  background: url(${BackgroundImage});
  background-repeat: no-repeat;
  background-size: cover;

  box-sizing: border-box;
  padding: 10rem 5rem;

  height: 51vw;

  color: #f5f5f5;

  @media (max-width: 1199px) {
    width: 100vw;
    height: 116vh;
    background: url(${BackgroundMobile});
    background-size: cover;
    padding: 5rem 1rem;
  }
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 25rem;
  width: 1199px;
  margin: auto;

  span {
    width: 40rem;
    font-size: 1.6rem;
    margin-bottom: 2rem;

    font-weight: bold;
  }

  h1 {
    width: 40rem;
    font-size: 1.3rem;
    margin-bottom: 2rem;
  }

  @media (max-width: 1199px) {
    height: 95%;
    width: 100%;
    padding: 0;

    align-items: flex-start;
    justify-content: flex-start;

    margin-top: 2rem;

    span {
      width: 75vw;
      font-size: 5vw;
      margin-bottom: 1rem;

      font-weight: bold;
    }

    h1 {
      width: 75vw;
      font-size: 4vw;
      margin-bottom: 1rem;
    }
  }
`;

export const ContainerButtons = styled.div`
  width: 26rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  button {
    padding: 1rem;
    font-size: 1.3rem;
  }

  @media (max-width: 1199px) {
    width: 35%;
    height: 90px;
    flex-direction: column;

    button {
      width: 100%;
      height: 40px;

      font-size: 0.8rem;
      padding: 0 10px;
    }

    img {
      width: 1rem;
    }
  }
`;
