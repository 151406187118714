import React from 'react';

import {
  Column,
  Container,
  ContainerChart,
  // ContainerNumber,
  ContainerTitle,
} from './styles';

import Ilustrations from './assets/Illustrations.png';

const Section3: React.FC = () => {
  return (
    <Container>
      <ContainerTitle>
        <p>Números atuais</p>
      </ContainerTitle>
      <ContainerChart>
        <Column>
          <p>Em constante crescimento 🚀</p>
          <span>
            Desde o início, estamos adquirindo dados, aprendendo como eles se
            comportam, identificando problemas e criando soluções para aumentar
            a efetividade da análise.
          </span>
        </Column>
        <Column>
          <img src={Ilustrations} alt="..." />
          {/* <ContainerNumber>
            <p>+800</p>
            <span>Contas gig conectadas</span>
          </ContainerNumber>
          <ContainerNumber>
            <p>+2000</p>
            <span>Contas bancárias conectadas</span>
          </ContainerNumber>
          <ContainerNumber>
            <p>+400k</p>
            <span>Transações bancárias analisadas</span>
          </ContainerNumber>
          <ContainerNumber>
            <p>+30k</p>
            <span>Transações de gig analisadas</span>
          </ContainerNumber> */}
        </Column>
      </ContainerChart>
    </Container>
  );
};

export default Section3;
