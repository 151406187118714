import React from 'react';
import Lottie from 'react-lottie';

import Balls from './assets/Loading.json';

import { Container } from './style';

const LoadingBalls: React.FC = () => {
  return (
    <Container>
      <div>
        <Lottie
          options={{ loop: true, autoplay: true, animationData: Balls }}
          style={{
            marginTop: -150,
          }}
          width={300}
          height={300}
        />
        <div className="text">
          <p>Em breve...</p>
        </div>
      </div>
    </Container>
  );
};

export default LoadingBalls;
