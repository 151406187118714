import React from 'react';
// import { useHistory } from 'react-router-dom';
import { Box, Container, ContainerImages, ContainerText } from './styles';

import FiInsiders from './assets/FiInsiders.svg';
import LiftLab from './assets/LiftLab.svg';
import StartupChile from './assets/StartupChile.svg';

const Section6: React.FC = () => {
  // const { push } = useHistory();
  return (
    <Container>
      <Box>
        <ContainerText>
          <div>
            <p>Junte-se ao time</p>
            <span>
              Venha fazer parte dessa história e nos ajude a democratizar o
              acesso a um crédito mais justo.
            </span>
          </div>
          <button
            type="button"
            onClick={() =>
              window.open(
                'https://api.whatsapp.com/send?phone=5521982015754&text=Ol%C3%A1,%20quero%20me%20juntar ao time!',
                '_blank',
                'noopener,noreferrer',
              )
            }
          >
            Me juntar
          </button>
        </ContainerText>
        <ContainerImages>
          <img src={FiInsiders} alt="..." />
          <img src={LiftLab} alt="..." />
          <img src={StartupChile} alt="..." />
        </ContainerImages>
      </Box>
    </Container>
  );
};

export default Section6;
