import styled from 'styled-components';

export const Container = styled.div`
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 350px;
    align-self: center;

    margin: 5em 5em;

    cursor: pointer;
  }

  @media (max-width: 1199px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 40%;
    }
  }
`;

export const Row = styled.div`
  width: 1199px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  margin-bottom: 5em;

  span {
    color: #171717;
    font-size: 24px;

    text-align: start;

    strong {
      color: #eec03f;
      font-weight: bold;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 1199px) {
    width: 100%;
    flex-direction: column;

    span {
      font-size: calc(18px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
    }
  }
`;
