import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 231px;
  height: 65px;

  padding: 0 25px;

  background-color: #eec03f;
  border-radius: 50px;

  font-size: 1rem;
  font-family: 'Poppins';
  color: #f5f5f5;
  border: 2px solid #eec03f;

  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #171717;
  }
`;

export const Image = styled.img``;
