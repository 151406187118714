import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #ffffff;

  box-sizing: border-box;
  padding: 5rem;

  @media (max-width: 1199px) {
    padding: 1rem;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 1199px;
  height: 25rem;
  background-color: rgba(238, 195, 63, 0.2);

  box-sizing: border-box;
  padding: 5rem 2rem;
  border-radius: 15px;

  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);

  @media (max-width: 1199px) {
    width: 100%;
    height: 30rem;
    flex-direction: column;
    padding: 3rem 2rem;
    margin-top: 2rem;
  }
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  width: 50%;
  height: 50%;

  p {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #171717;
  }

  span {
    font-size: 1.1rem;
    text-align: start;
    color: #828282;
  }

  @media (max-width: 1199px) {
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    width: auto;

    p {
      font-size: 1rem;
      margin-bottom: 1rem;
      align-self: flex-start;
    }

    span {
      font-size: 1rem;
    }
  }
`;

export const ContainerImages = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  height: 50%;
  width: 30%;

  img {
    width: 15rem;
  }

  @media (max-width: 1199px) {
    height: 20%;
    margin-top: 2rem;

    img {
      width: 50vw;

      &:nth-child(1) {
        margin-bottom: 1rem;
      }
    }
  }
`;
