import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const SelectContainer = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  align-items: center;

  select {
    width: 100%;
    height: 50px;
    background-color: #f5f5f5;
    border: 2px solid #adadad;
    border-radius: 8px;
    padding: 0 40px 0 16px;
    font-weight: 400;
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='%23666666' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 16px center;

    /* Forçar dropdown para baixo */
    position: relative;
    z-index: 1;

    &:focus {
      border-color: #2a2a2a;
      outline: none;
    }

    &:invalid,
    option[value=''] {
      color: #999;
    }

    option {
      background-color: #f5f5f5;
      color: #666;
      font-size: 16px;
      padding: 8px;
    }

    &::-ms-expand {
      display: none;
    }

    ${props =>
      props.isErrored &&
      css`
        border-color: #c53030;
      `}

    ${props =>
      props.isFocused &&
      css`
        border-color: #171717;
      `}
  }

  /* Container do ícone de erro */
  & > div {
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    margin-top: -10px; /* Ajuste para compensar a margin-bottom do select */
    display: flex;
    align-items: center;
    z-index: 2;
  }

  @media (max-width: 1199px) {
    width: 100%;

    select {
      width: 100%;
    }
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  display: flex;
  align-items: center;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #ffffff !important;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
