/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Background, Container, ContainerList } from './styles';
import Sanduiche from './assets/Sanduiche.svg';
import ChevronLeft from './assets/arrow-right.svg';

const ButtonMenuMobile: React.FC = () => {
  const [visibilityMenu, setVisibilityMenu] = useState(false);

  return (
    <>
      <Container visibility={visibilityMenu}>
        <button
          type="button"
          onClick={() => setVisibilityMenu(!visibilityMenu)}
        >
          <img src={Sanduiche} alt="menu mobile" />
        </button>
      </Container>
      <Background visibility={visibilityMenu}>
        <button
          type="button"
          onClick={() => setVisibilityMenu(!visibilityMenu)}
        >
          <img src={ChevronLeft} alt="..." />
        </button>
        <ContainerList>
          <li>
            <Link to="/about">Sobre nós</Link>
          </li>
          <li>
            <span
              onClick={() =>
                window.open(
                  'https://api.whatsapp.com/send?phone=5521982015754&text=Ol%C3%A1,%20quero%20fazer%20parte!',
                  '_blank',
                  'noopener,noreferrer',
                )
              }
            >
              Fazer parte
            </span>
          </li>
        </ContainerList>
      </Background>
    </>
  );
};

export default ButtonMenuMobile;
