import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  height: auto;

  background-color: #f5f5f5;

  @media (max-width: 1199px) {
    width: 100%;
    padding: 2rem 1rem;

    height: auto;

    p {
      font-size: 5vw;
    }
  }
`;

export const ContainerTitle = styled.div`
  width: 1199px;

  margin-top: 5rem;

  p {
    font-size: 2.2rem;
    font-weight: bold;
    color: #171717;
    align-self: flex-start;
    margin-bottom: 2rem;
  }

  @media (max-width: 1199px) {
    width: 100%;
    p {
      font-size: 5vw;
    }
  }
`;

export const Enterprise = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 1199px;

  margin-bottom: 5rem;

  img {
    width: 12rem;
    margin: 0;
    transition: transform 0.5s ease-in-out;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }
  }

  @media (max-width: 1199px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    justify-items: center;
    justify-content: center;

    img {
      width: 35vw;
      margin: 5vw;
      &:hover {
      }

      &:nth-child(1) {
        width: 20vw;
      }

      &:nth-child(3) {
        width: 25vw;
      }
    }
  }
`;
