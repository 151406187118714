import React from 'react';
import './animation.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
// import Section4 from './components/Section4';
// import InfoSecurity from './components/InfoSecurity';
import Home from './components/Home';
// import WhatsFind from './components/WhatsFind';
// import SyncAccount from './components/SyncAccounts';
// import Section3 from './components/Section3';
import SubFooter from '../../components/SubFooter';

const Homepage: React.FC = () => {
  document.title =
    'Plific: Tenha acesso a um crédito adaptado a sua realidade!';

  return (
    <>
      <Header />

      <Home />

      {/* <WhatsFind />

      <SyncAccount />

      <Section3 />

      <Section4 />

      <InfoSecurity /> */}

      <Footer />

      <SubFooter />
    </>
  );
};

export default Homepage;
