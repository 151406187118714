import React from 'react';
import { Container } from './styles';

const Section7: React.FC = () => {
  return (
    <Container>
      <p>Converse conosco</p>
      <h1>Envie dúvidas, sugestões ou conheça nosso trabalho.</h1>
      <a href="mailto:contato@plific.com" target="_blank" rel="noreferrer">
        contato@plific.com
      </a>
    </Container>
  );
};

export default Section7;
