import React, { useState } from 'react';
import { Box, Container, ContainerImages, ContainerText } from './styles';

import FiInsiders from './assets/FiInsiders.svg';
import LiftLab from './assets/LiftLab.svg';
import StartupChile from './assets/StartupChile.svg';

const Section5: React.FC = () => {
  const [width, setWidth] = useState(window.innerWidth);

  window.addEventListener('resize', () => {
    setWidth(window.innerWidth);
  });
  return (
    <Container>
      <Box>
        <ContainerText>
          <p>Conquistas</p>
          <span>
            Veja os prêmios e reconhecimentos conquistados pela Plific devido a
            inovação e diferencial de sua atuação no mercado financeiro.
          </span>
        </ContainerText>
        <ContainerImages>
          <img src={FiInsiders} alt="..." />
          <img src={StartupChile} alt="..." />
        </ContainerImages>
        <img
          src={LiftLab}
          alt="..."
          style={{
            width: width > 1199 ? '10rem' : '25vw',
            marginTop: width > 1199 ? '0' : '2rem',
          }}
        />
      </Box>
    </Container>
  );
};

export default Section5;
