import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 0 0 5rem;
  transition: background-color 0.5s linear;
  position: fixed;
  z-index: 999;

  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style: none;

    li {
      margin-left: 3vw;
      height: 100%;
      display: flex;
      align-items: center;

      a,
      button {
        display: flex;
        align-items: center;
        height: 100%;
        text-decoration: none;
        padding: 0 1rem;
        background: none;
        border: none;
        cursor: pointer;
        transition: all 0.3s ease;

        span {
          font-size: 1rem;
          font-weight: 500;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            width: 0;
            height: 2px;
            bottom: -4px;
            left: 0;
            background-color: #eec03f;
            transition: width 0.3s ease;
          }
        }

        &:hover {
          span::after {
            width: 100%;
          }
        }
      }

      &:last-child {
        margin-left: 0;

        button {
          background-color: #eec03f;
          padding: 0 2rem;
          height: 100%;
          transition: all 0.3s ease;
          overflow: hidden;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: -100%;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.1);
            transition: transform 0.3s ease;
          }

          &:hover {
            background-color: #f7c841;

            &::before {
              transform: translateX(100%);
            }
          }
        }
      }
    }
  }

  @media (max-width: 1199px) {
    justify-content: center;
    margin-right: 0;

    h1 {
      margin: 0;
    }

    ul {
      display: none;
    }
  }
`;

export const Logo = styled.h1`
  font-size: 30px;
  font-weight: bolder;
  font-family: 'Montserrat Alternates';
  cursor: pointer;
  display: flex;
  align-items: center;

  &::after {
    content: '.';
    color: #eec03f;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  @media (max-width: 1199px) {
    margin-left: 0;
    position: absolute;
    left: 1rem;
    top: 22%;
  }
`;
