import styled, { css } from 'styled-components';

interface BackgroundProps {
  visibility?: boolean;
}

export const Container = styled.button<BackgroundProps>`
  background-color: transparent;
  display: none;
  align-items: center;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  padding: 8px;
  z-index: 30;

  button {
    border: none;
    background-color: transparent;
  }

  img {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 1199px) {
    display: flex;
  }
`;

export const Background = styled.div<BackgroundProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #ffffff;
  width: 100%;
  min-height: 100vh;
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 28;
  position: fixed;
  right: 0;
  top: 0;
  padding-top: 80px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);

  ${props =>
    props.visibility
      ? css`
          transform: translateY(0);
        `
      : css`
          transform: translateY(-100%);
        `};

  button {
    border: none;
    background-color: transparent;
    position: absolute;
    top: 23px;
    right: 1rem;
    padding: 8px;
    transform: rotate(-90deg);
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      transform: rotate(-90deg) scale(1.1);
    }

    img {
      width: 24px;
      height: 24px;
    }
  }
`;

export const ContainerList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 2rem;

  li {
    list-style: none;
    margin-bottom: 2rem;
    width: 100%;
    text-align: center;

    a,
    span {
      font-size: 24px;
      color: #171717;
      text-decoration: none;
      display: inline-block;
      position: relative;
      padding: 8px 16px;
      transition: all 0.3s ease;
      cursor: pointer;

      &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 2px;
        bottom: 0;
        left: 50%;
        background-color: #eec03f;
        transition: all 0.3s ease;
      }

      &:hover {
        color: #000;
        transform: translateY(-2px);

        &::after {
          width: 80%;
          left: 10%;
        }
      }
    }

    &:last-child {
      margin-top: 1rem;

      span {
        background-color: #eec03f;
        color: #2a2a2a;
        padding: 12px 24px;
        border-radius: 8px;
        font-weight: 500;

        &::after {
          display: none;
        }

        &:hover {
          background-color: #f7c841;
          transform: translateY(-2px);
        }
      }
    }
  }
`;
