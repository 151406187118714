import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;

  box-sizing: border-box;

  background-color: #fff;

  margin: 0;

  @media (max-width: 1199px) {
    padding: 0 1rem;
    height: auto;
  }
`;

export const Enterprise = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: center;

  width: 1199px;

  margin: 0;

  background-color: #fff;

  margin-top: 2rem;
  margin-bottom: 2rem;

  img {
    width: 6vw;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }

  img:hover {
    transform: scale(1.05);
  }

  @media (max-width: 1199px) {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 2rem;
    justify-items: center;
    align-items: center;

    img {
      width: 20vw;
      margin-bottom: 5vw;
    }
  }
`;

export const StartingPoint = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: center;
  width: 1199px;
  margin: 0;

  background-color: #fff;

  img {
    width: 20rem;
    min-width: 210px;
    margin-top: 5em;
  }

  @media (max-width: 1199px) {
    width: 100%;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: auto;

  p {
    font-size: 2.2rem;
    font-weight: bold;
    align-self: flex-start;
    color: #171717;
    margin-bottom: 1em;
  }

  h1 {
    width: 35vw;
    min-width: 600px;
    text-align: justify;
    color: #828282;
  }

  h2 {
    width: 35vw;
    min-width: 600px;
    box-sizing: border-box;
    align-self: flex-start;
    color: #171717;
    text-align: justify;
    background-color: #f5f5f5;
    border-radius: 10px;
    margin-top: 2rem;
    padding: 10px;

    span {
      font-weight: bold;
    }
  }

  h3 {
    width: 35vw;
    min-width: 600px;
    color: #171717;
    margin-top: 2rem;
    text-align: justify;
  }

  @media (max-width: 1199px) {
    width: 100%;

    p {
      font-size: 1rem;
    }

    h1 {
      font-size: 1rem;
      width: 100%;
      min-width: auto;
    }

    h2 {
      font-size: 1rem;
      width: 100%;
      min-width: auto;

      padding: 18px;

      span {
        font-weight: bold;
      }
    }

    h3 {
      font-size: 1rem;
      width: 100%;
      min-width: auto;

      text-align: justify;
    }

    img {
      margin-top: 2rem;
      width: 90vw;
    }
  }
`;
